<template>
  <div class="payment-disbursement-approval-filter">
    <bg-grid>
      <bg-grid-item
        :col="6"
        class="payment-disbursement-approval-filter__period"
      >
        <bg-datepicker
          v-model="selectedMonthDateValue"
          minimum-view="month"
          maximum-view="month"
          format="MMMM yyyy"
          @input="selectMonth"
          size="lg"
        />

        <div class="flex justify-space-between gap-md bg-u-mt-md">
          <bg-select
            v-model="periodFilters.period"
            :options="DISBURSEMENT_PERIOD_TYPES"
            @change="resetSelectedTransferDate"
          />

          <bg-select
            v-model="periodFilters.transfer_date"
            :options="TRANSFER_DATE_OPTIONS[periodFilters.period]"
          />
        </div>
      </bg-grid-item>

      <bg-grid-item :col="6">
        <slot name="upper-right-space" />
      </bg-grid-item>
    </bg-grid>

    <bg-grid class="mb-32" :v-align="'center'">
      <bg-grid-item
        class="payment-disbursement-approval-filter__additional mb-0"
        :col="10"
      >
        <property-search-bar
          v-model="propertyNameFilter"
          :url="APIPropertySuggestion"
          :placeholder="'Cari Nama Properti'"
          @handle-submit-filter="handleSubmitFilter"
        />

        <div>
          <bg-button
            size="lg"
            class="payment-disbursement-approval-filter__filter-modal-cta"
            @click="openFilterModal"
          >
            <bg-badge-counter
              v-if="totalModalFilter"
              :value="totalModalFilter"
              variant="black"
            />

            <bg-icon v-else name="filter" size="sm" />

            <span class="ml-8">Filter</span>
          </bg-button>

          <bg-button
            size="lg"
            class="ml-16"
            @click="handleResetAdditionalFilters"
          >
            Reset
          </bg-button>

          <bg-button
            size="lg"
            @click="handleSubmitFilter"
            variant="primary"
            class="payment-disbursement-approval-filter__main-action ml-16"
          >
            Cari
          </bg-button>
        </div>
      </bg-grid-item>

      <bg-grid-item class="mb-0 ta-r" :col="2">
        <router-link :to="{ name: 'disbursement.add-manual-transfer' }">
          <bg-button class="ml-16" size="lg">Input Manual</bg-button>
        </router-link>
      </bg-grid-item>
    </bg-grid>

    <!-- ADDITIONAL FILTER MODAL -->
    <bg-modal
      v-model="isModalFilterOpen"
      desktop-size="lg"
      button-main-text="Terapkan"
      button-second-text="Reset"
      title="Filter Transfer Pendapatan Pemilik"
      description="Silakan pilih sesuai kebutuhan anda"
      @click-main-action="handleSubmitFilter"
      @click-second-action="handleResetModalFilter"
    >
      <bg-grid class="bg-u-mt-xl">
        <bg-grid-item :col="6">
          <bg-text size="title-3" class="mb-16">Status Transfer</bg-text>

          <search-checkbox
            name="transferStatus"
            placeholder="Pilih status transfer"
            :list="transferStatus.lists"
            :checked="modalFilters.transfer_status"
            :is-loading-list="transferStatus.loading"
            :max-checked="0"
            disable-search
            @emit-checked-array="modalFilters.transfer_status = $event"
          />
        </bg-grid-item>

        <bg-grid-item :col="6">
          <bg-text size="title-3" class="mb-16">Status Data Pendapatan</bg-text>

          <search-checkbox
            name="transferIncome"
            placeholder="Pilih status data pendapatan"
            :list="incomeStatus.lists"
            :checked="modalFilters.status"
            :is-loading-list="incomeStatus.loading"
            :max-checked="0"
            disable-search
            dropdown-menu-placement="bottom-end"
            @emit-checked-array="modalFilters.status = $event"
          />
        </bg-grid-item>

        <bg-grid-item :col="6">
          <bg-text size="title-3" class="mb-16">AS</bg-text>

          <search-checkbox
            name="accountSupport"
            placeholder="Pilih AS"
            :list="accountSupport.lists"
            :checked="modalFilters.account_support"
            :is-loading-list="accountSupport.loading"
            :max-checked="0"
            disable-search
            dropdown-menu-placement="bottom-end"
            @emit-checked-array="modalFilters.account_support = $event"
            @dropdown-open="fetchOfficerList"
          />
        </bg-grid-item>
      </bg-grid>
    </bg-modal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {
  BgGrid,
  BgGridItem,
  BgButton,
  BgDatepicker,
  BgModal,
  BgBadgeCounter,
  BgIcon,
  BgText,
  BgSelect,
} from 'bangul-vue';

import PropertySearchBar from '@admin_molecules/PropertySearchBar';

import formatter from '@admin/mixins/formatter';

import homepageApi from '@admin_endpoints/homepage';

import EventBus from 'Utils/event-bus';
import { getSessionStorage, setSessionStorage } from 'Utils/session-storage';

import TRANSFER_DATE_OPTIONS from '../../constants/transfer-date-options';
import DISBURSEMENT_PERIOD_TYPES from '../../constants/disbursement-period-types';

const PERIOD_FILTERS = {
  month: dayjs().format('MM'),
  year: dayjs().format('YYYY'),
  transfer_date: dayjs().date() < 16 ? 1 : 16,
  period: 'monthly'
};

const MODAL_FILTERS = {
  transfer_status: [],
  status: [],
  account_support: [],
}

export default {
  name: 'PaymentDisbursementApprovalFilter',
  components: {
    BgGrid,
    BgGridItem,
    BgButton,
    BgDatepicker,
    BgModal,
    BgBadgeCounter,
    BgIcon,
    BgText,
    BgSelect,
    PropertySearchBar,
    SearchCheckbox: () => import('@admin_molecules/SearchCheckbox'),
  },

  mixins: [formatter],

  data() {
    return {
      filterName: '',
      selectedMonthDateValue: new Date(),
      isFilterStatusOpen: false,
      isModalFilterOpen: false,
      periodFilters: { ...PERIOD_FILTERS },
      modalFilters: { ...MODAL_FILTERS },
      propertyNameFilter: '',
      APIPropertySuggestion:
        '/singgahsini/api/income/approval-property-disbursement/properties-suggestion',
      transferStatus: {
        loading: false,
        lists: [
          { label: 'Waiting List', value: 'waiting' },
          { label: 'Processing', value: 'processing' },
          { label: 'Failed', value: 'failed' },
          { label: 'Transferred', value: 'transferred' },
        ],
      },
      incomeStatus: {
        loading: false,
        lists: [
          { label: 'Dikonfirmasi', value: 'approved' },
          { label: 'Menunggu Konfirmasi', value: 'unapproved' },
        ],
      },
      accountSupport: {
        loading: false,
        lists: [],
      },
      TRANSFER_DATE_OPTIONS,
      DISBURSEMENT_PERIOD_TYPES,
    };
  },

  computed: {
    totalModalFilter() {
      let total = 0;
      const values = Object.values(this.modalFilters);
      values.forEach(value => {
        if (value.length) total += 1;
      });
      return total;
    },

    additionalFilters() {
      return {
        ...this.modalFilters,
        property_name: this.propertyNameFilter,
      };
    },
  },

  created() {
    this.initPeriodFilters();
    this.initAdditionalFilters();
  },

  methods: {
    initPeriodFilters() {
      if (!getSessionStorage('periodFilters')) {
        return;
      }

      this.periodFilters = getSessionStorage('periodFilters');

      if (this.periodFilters.month && this.periodFilters.year) {
        this.selectedMonthDateValue = new Date(
          `${this.periodFilters.month}-01-${this.periodFilters.year}`
        );
      }
    },

    initAdditionalFilters() {
      if (!getSessionStorage('additionalFilters')) {
        return;
      }

      const additionalFilters = getSessionStorage('additionalFilters');

      Object.keys(MODAL_FILTERS).forEach(key => {
        if (additionalFilters[key]) {
          this.modalFilters[key] = additionalFilters[key];
        }
      });

      this.propertyNameFilter = additionalFilters.property_name;
    },

    handleSubmitFilter() {
      setSessionStorage('periodFilters', this.periodFilters);
      setSessionStorage('additionalFilters', this.additionalFilters);

      EventBus.$emit('onSubmitFilter', {
        ...this.periodFilters,
        ...this.additionalFilters,
      });
    },

    handleResetAdditionalFilters() {
      this.propertyNameFilter = '';

      this.handleResetModalFilter();
    },

    handleResetModalFilter() {
      this.modalFilters = MODAL_FILTERS;

      this.handleSubmitFilter();
    },

    selectMonth() {
      const year = dayjs(this.selectedMonthDateValue).format('YYYY');
      const month = dayjs(this.selectedMonthDateValue).format('MM');

      this.periodFilters.month = month;
      this.periodFilters.year = year;
    },

    resetSelectedTransferDate() {
      this.periodFilters.transfer_date = PERIOD_FILTERS.transfer_date
    },

    openFilterModal() {
      this.isModalFilterOpen = true;
    },

    async fetchOfficerList() {
      const needFetchData = !this.accountSupport.lists.length;

      const mappingData = datas =>
        !datas ? [] : datas.map(data => ({ label: data, value: data }));

      if (!this.accountSupport.loading && needFetchData) {
        this.accountSupport.loading = true;

        try {
          const { data } = await homepageApi.getFilterOfficer();

          if (data.status) {
            this.accountSupport.lists = mappingData(
              data.data.account_support_name
            );
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.accountSupport.loading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" src="./PaymentDisbursementApprovalFilter.scss" scoped />
